import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
import ArticlesContainer from 'components/Sections/Articles/ArticlesContainer'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IContextWithPageNav } from 'models/Context'
import { IArticleCard } from 'models/Article'
import { ICategory } from 'models/Category'

type ArticleProps = {
    articles: { nodes: IArticleCard[] }
    featured: { nodes: IArticleCard[] }
    category: ICategory
    pageSeo: ISeoQuery
}

const ArticlePage: React.FC<PageProps<ArticleProps, IContextWithPageNav>> = ({
    data,
    pageContext: context,
}) => {
    const articles = data.articles.nodes
    const featured = data.featured.nodes
    const category = data.category
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }
    return (
        <Layout seo={seo}>
            <SectionHeader title={category.name} image={pageSeo.image} />
            <ArticlesContainer
                articles={articles}
                featured={featured}
                context={context}
            />
        </Layout>
    )
}

export default ArticlePage

export const pageQuery = graphql`
    query CategoryPage($limit: Int!, $skip: Int!, $category: String!) {
        articles: allContentfulArticles(
            filter: { categories: { elemMatch: { slug: { eq: $category } } } }
            sort: { fields: date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            nodes {
                title
                slug
                date
                excerpt {
                    text: excerpt
                }
                author {
                    name
                    username
                    avatar {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                categories {
                    name
                    slug
                    color
                }
                thumbnail {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
        }

        featured: allContentfulArticles(
            sort: { fields: date, order: DESC }
            filter: { featured: { eq: true } }
            limit: 3
        ) {
            nodes {
                title
                slug
                date
                thumbnail {
                    gatsbyImageData(width: 350)
                }
            }
        }

        category: contentfulCategories(slug: { eq: $category }) {
            slug
            name
        }

        pageSeo: contentfulSeo(slug: { eq: "categories" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
